import { initAppConfig, Providers } from '@custom/ui/routes/Providers';
import { GatsbyBrowser, WrapPageElementBrowserArgs } from 'gatsby';
import React from 'react';

import { i18n, Locale } from '../../i18n';
import { APP_CONFIG } from '../constants';
import { PageContext } from '../types';

initAppConfig(APP_CONFIG as any);

export const WrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}: WrapPageElementBrowserArgs<any, PageContext>) => {
  return (
    <Providers
      domain={(props.pageContext.domain?.id || 'paraplegie_ch') as any}
      i18n={i18n}
      path={props.path}
      locale={props.pageContext.locale as Locale}
      localizations={
        props.pageContext.localizations as Array<{
          path: string;
          locale: Locale;
        }>
      }
    >
      {element}
    </Providers>
  );
};
