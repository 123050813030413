/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Import a stylesheet from another package
// import '@my-project/ui/styles.css';

import './src/assets/sass/global/main.scss';

import { GatsbyBrowser } from 'gatsby';

import { WrapPageElement } from './src/utils/wrapPageElement';
import { WrapRootElement } from './src/utils/wrapRootElement';

declare global {
  interface Window {
    locations: any;
    previousPath: any;
  }

  namespace JSX {
    interface IntrinsicElements {
      'storifyme-collection': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export const wrapPageElement = WrapPageElement;

export const wrapRootElement = WrapRootElement;

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  pathname,
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // Some filters are implemented using the Gatsby's navigate function. This
  // needs to be re-done at some point. Yet currently we apply this workaround
  // to prevent the page from scrolling to the top when the filters are
  // applied.
  const urls = [
    // Doctors
    '/en/doctors',
    '/de/doctors',
    '/fr/doctors',
    '/it/doctors',
    // Company Members list
    '/en/support-us/memberships/become-corporate-member/company-member-list',
    '/de/uns-unterstuetzen/mitgliedschaften/firmen-mitglied-werden/firmen-verzeichnis/',
    '/fr/nous-soutenir/affiliations/affilier-son-entreprise/repertoire-des-entreprises/',
    '/it/come-sostenerci/affiliazioni/sottoscrivere-unaffiliazione-aziendale/elenco-aziende/',
    // Events
    '/en/events',
    '/de/events',
    '/fr/events',
    '/it/events',
    '/en/events/all',
    '/de/events/all',
    '/fr/events/all',
    '/it/events/all',
    // Search
    '/en/search',
    '/de/search',
    '/fr/search',
    '/it/search',
    // History
    '/en/history',
    '/de/history',
    '/fr/history',
    '/it/history',
  ];
  let allowUpdateScroll = false;

  const referrer = window.previousPath;
  const domainPrefix = '(/[^/]+)?';
  const regex = new RegExp(`^${domainPrefix}((${urls.join(')|(')}))/?$`);
  const noScroll = regex.test(pathname);
  const previousInUrls = referrer ? regex.test(referrer) : false;

  const currentPosition = getSavedScrollPosition(location);

  // If we can scroll i.e. not on the list of URLs above.
  if (!noScroll) {
    allowUpdateScroll = true;
  } else if (noScroll && location.search === '' && !previousInUrls) {
    // If we are on the list of URLs above & there is no search query, i.e. first time on the page.
    // Also check the previous url is not on the list of URLs above.
    // This is to prevent the page from scrolling to the top when the filters are removed.
    allowUpdateScroll = true;
  }

  // Views can be set on any paths, so check if filters
  // are used to prevent the page from scrolling to the top.
  const filterQueryStrings = [
    'search',
    'searchTerm',
    'type',
    'glossary',
    'field',
    'doctorFunction',
    'organisation',
    'month',
    'category',
    'period',
    'domain',
  ];
  if (
    location.pathname === referrer ||
    filterQueryStrings.some((item) => location.search.includes(item))
  ) {
    allowUpdateScroll = false;
  }

  // If allowed to scroll, scroll to the saved position.
  if (allowUpdateScroll) {
    // Wrapping with setTimeout is a workaround for the issue described here:
    // For some reason the back button is used
    // loading the page the current position happens too early(?) & you end up lower down the page then expected.
    // using setTimeout with 0 delay seems to fix this issue.
    setTimeout(() => {
      window.scrollTo(...(currentPosition || [0, 0]));
    }, 0);
  }

  // Otherwise not allowed to update scroll.
  return false;
};

export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer];
  // @ts-ignore
  locations.push(window.location.pathname);
  // @ts-ignore
  window.previousPath = locations[locations.length - 1];
};
