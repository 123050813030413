import { DomainConfig } from '../types';

// TODO: De-duplicate

export const DOMAINS: DomainConfig = {
  paraplegie_ch: {
    id: 'paraplegie_ch',
    name: 'paraplegie_ch.name',
    title: 'paraplegie_ch.title',
    protocol: 'https://',
    host: 'www.paraplegie.ch',
    port: '',
    formattedHost: 'https://paraplegie.ch',
    fullUrl: 'https://paraplegie.ch',
    homepagePath: '/home',
    logo1x: {
      en: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
      de: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
      fr: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
      it: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
    },
    headerLogo1x: '/img/logos/svg/spg-compact.svg',
    headerLogo2x: '/img/logos/svg/spg-compact.svg',
    desktopLogo1x: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
    groupSVGWhite: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-white.svg',
    groupSVG: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
    groupSVGMobile: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
    logosvg: {
      en: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
      de: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
      fr: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
      it: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
    },
    logoNoPaddingSvg: {
      en: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Gruppe.svg',
      de: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Gruppe.svg',
      fr: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Gruppe.svg',
      it: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Gruppe.svg',
    },
    mail: 'info@paraplegie.ch',
    tel: '+41419395606',
    formattedTel: '+41 41 939 56 06',
    hideContactInSidebar: true,
    sidebarMenu: 'main',
    quickLinksMenu: '',
    sidebarMaxDepth: 2,
    sidebarHideRootsIfActive: true,
    sidebarClass: 'theme-sps',
    hideSidebarOnMobile: true,
    mainMenu: 'main',
    metaNavigationMenu: 'meta-navigation',
    bodyClass: 'theme-default',
    showInOverview: false,
    overviewPageImage: '',
    overviewPageText: 'paraplegie_ch.text',
    favicons: {
      android_chrome_192: '/img/favicons/android-chrome-192x192.png',
      android_chrome_256: '/img/favicons/android-chrome-256x256.png',
      apple_touch_icon: '/img/favicons/apple-touch-icon.png',
      favicon_16: '/img/favicons/favicon-16x16.png',
      favicon_32: '/img/favicons/favicon-32x32.png',
    },
    newsletterUrls: {
      de: 'https://www.paraplegie.ch/de/anmeldung-newsletterUrls-schweizer-paraplegiker-stiftung',
      fr: 'https://www.paraplegie.ch/fr/abonnement-la-newsletterUrls',
      it: 'https://www.paraplegie.ch/it/iscrizione-alla-newsletterUrls',
    },
  },
  stiftung_paraplegie_ch: {
    id: 'stiftung_paraplegie_ch',
    name: 'stiftung_paraplegie_ch.name',
    title: 'stiftung_paraplegie_ch.title',
    protocol: 'https://',
    host: 'www.paraplegie.ch',
    port: '',
    formattedHost: 'https://paraplegie.ch',
    fullUrl: 'https://paraplegie.ch/sps',
    pathPrefix: '/sps',
    homepagePath: '/home-sps',
    logo1x: {
      en: '/img/logos/svg/Schweizer-Paraplegiker-Stiftung-en.svg',
      de: '/img/logos/svg/Schweizer-Paraplegiker-Stiftung-de.svg',
      fr: '/img/logos/svg/Schweizer-Paraplegiker-Stiftung-fr.svg',
      it: '/img/logos/svg/Schweizer-Paraplegiker-Stiftung-it.svg',
    },
    headerLogo1x: '/img/logos/svg/sps-compact.svg',
    desktopLogo1x: '/img/logos/svg/Schweizer-Paraplegiker-Stiftung-de.svg',
    groupSVGWhite: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-white.svg',
    groupSVG: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
    groupSVGMobile: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
    logosvg: {
      en: '/img/logos/svg/Schweizer-Paraplegiker-Stiftung-en.svg',
      de: '/img/logos/svg/Schweizer-Paraplegiker-Stiftung-de.svg',
      fr: '/img/logos/svg/Schweizer-Paraplegiker-Stiftung-fr.svg',
      it: '/img/logos/svg/Schweizer-Paraplegiker-Stiftung-it.svg',
    },
    logoNoPaddingSvg: {
      en: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Stiftung-en.svg',
      de: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Stiftung-de.svg',
      fr: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Stiftung-fr.svg',
      it: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Stiftung-it.svg',
    },
    mail: 'sps.sec@paraplegie.ch',
    tel: '+41419396363',
    formattedTel: '+41 41 939 63 63',
    sidebarMenu: 'sidebar-sps',
    quickLinksMenu: 'footer-quicklinks-sps',
    organisationFooterSocialMenu: 'footer-social-menu-sps',
    sidebarMaxDepth: 2,
    mainMenu: 'main',
    metaNavigationMenu: 'meta-navigation',
    bodyClass: 'theme-sps',
    showInOverview: true,
    overviewPageImage: '/domainPictures/overview/sps.jpg',
    overviewPageText: 'stiftung_paraplegie_ch.text',
    favicons: {
      android_chrome_192: '/img/favicons/sps/android-chrome-192x192.png',
      android_chrome_256: '/img/favicons/sps/android-chrome-256x256.png',
      apple_touch_icon: '/img/favicons/sps/apple-touch-icon.png',
      favicon_16: '/img/favicons/sps/favicon-16x16.png',
      favicon_32: '/img/favicons/sps/favicon-32x32.png',
    },
    newsletterUrls: {},
  },
  zentrum_paraplegie_ch: {
    id: 'zentrum_paraplegie_ch',
    name: 'zentrum_paraplegie_ch.name',
    title: 'zentrum_paraplegie_ch.title',
    protocol: 'https://',
    host: 'www.paraplegie.ch',
    port: '',
    formattedHost: 'https://paraplegie.ch',
    fullUrl: 'https://paraplegie.ch/spz',
    pathPrefix: '/spz',
    homepagePath: '/home-spz',
    logo1x: {
      en: '/img/logos/svg/Schweizer-Paraplegiker-Zentrum-en.svg',
      de: '/img/logos/svg/Schweizer-Paraplegiker-Zentrum-de.svg',
      fr: '/img/logos/svg/Schweizer-Paraplegiker-Zentrum-fr.svg',
      it: '/img/logos/svg/Schweizer-Paraplegiker-Zentrum-it.svg',
    },
    headerLogo1x: '/img/logos/svg/spz-compact.svg',
    headerLogo2x: '/img/logos/svg/spz-compact.svg',
    desktopLogo1x: '/img/logos/svg/Schweizer-Paraplegiker-Zentrum-de.svg',
    desktopLogo2x: '/img/logos/svg/Schweizer-Paraplegiker-Zentrum-de.svg',
    groupSVGWhite: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-white.svg',
    groupSVG: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
    groupSVGMobile: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
    logosvg: {
      en: '/img/logos/svg/Schweizer-Paraplegiker-Zentrum-en.svg',
      de: '/img/logos/svg/Schweizer-Paraplegiker-Zentrum-de.svg',
      fr: '/img/logos/svg/Schweizer-Paraplegiker-Zentrum-fr.svg',
      it: '/img/logos/svg/Schweizer-Paraplegiker-Zentrum-it.svg',
    },
    logoNoPaddingSvg: {
      en: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Zentrum-en.svg',
      de: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Zentrum-de.svg',
      fr: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Zentrum-fr.svg',
      it: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Zentrum-it.svg',
    },
    mail: 'spz@paraplegie.ch',
    tel: '+41419395454',
    formattedTel: '+41 41 939 54 54',
    sidebarMenu: 'sidebar-spz',
    quickLinksMenu: 'footer-quicklinks-spz',
    organisationFooterSocialMenu: 'footer-social-menu-spz',
    sidebarMaxDepth: 2,
    bodyClass: 'theme-spz',
    mainMenu: 'main',
    metaNavigationMenu: 'meta-navigation',
    showInOverview: true,
    overviewPageImage: '/domainPictures/overview/spz.jpg',
    overviewPageText: 'zentrum_paraplegie_ch.text',
    favicons: {
      android_chrome_192: '/img/favicons/spz/android-chrome-192x192.png',
      android_chrome_256: '/img/favicons/spz/android-chrome-256x256.png',
      apple_touch_icon: '/img/favicons/spz/apple-touch-icon.png',
      favicon_16: '/img/favicons/spz/favicon-16x16.png',
      favicon_32: '/img/favicons/spz/favicon-32x32.png',
    },
    newsletterUrls: {
      de: 'https://elaine.spz.ch/pl/d1c/51fa/subscribe/de',
    },
  },
  forschung_paraplegie_ch: {
    id: 'forschung_paraplegie_ch',
    name: 'forschung_paraplegie_ch.name',
    title: 'forschung_paraplegie_ch.title',
    protocol: 'https://',
    host: 'www.paraplegie.ch',
    port: '',
    formattedHost: 'https://paraplegie.ch',
    pathPrefix: '/spf',
    fullUrl: 'https://paraplegie.ch/spf',
    homepagePath: '/home-spf',
    logo1x: {
      en: '/img/logos/svg/Schweizer-Paraplegiker-Forschung-en.svg',
      de: '/img/logos/svg/Schweizer-Paraplegiker-Forschung-de.svg',
      fr: '/img/logos/svg/Schweizer-Paraplegiker-Forschung-fr.svg',
      it: '/img/logos/svg/Schweizer-Paraplegiker-Forschung-it.svg',
    },
    headerLogo1x: '/img/logos/svg/spf-compact.svg',
    desktopLogo1x: '/img/logos/svg/Schweizer-Paraplegiker-Forschung-de.svg',
    groupSVGWhite: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-white.svg',
    groupSVG: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
    groupSVGMobile: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
    logosvg: {
      en: '/img/logos/svg/Schweizer-Paraplegiker-Forschung-en.svg',
      de: '/img/logos/svg/Schweizer-Paraplegiker-Forschung-de.svg',
      fr: '/img/logos/svg/Schweizer-Paraplegiker-Forschung-fr.svg',
      it: '/img/logos/svg/Schweizer-Paraplegiker-Forschung-it.svg',
    },
    logoNoPaddingSvg: {
      en: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Forschung-en.svg',
      de: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Forschung-de.svg',
      fr: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Forschung-fr.svg',
      it: '/img/logos/svg/no-padding/Schweizer-Paraplegiker-Forschung-it.svg',
    },
    mail: 'spf@paraplegie.ch',
    tel: '+41419396565',
    formattedTel: '+41 41 939 65 65',
    sidebarMenu: 'sidebar-spf',
    quickLinksMenu: 'footer-quicklinks-spf',
    organisationFooterSocialMenu: 'footer-social-menu-spf',
    sidebarMaxDepth: 2,
    mainMenu: 'main',
    metaNavigationMenu: 'meta-navigation',
    bodyClass: 'theme-spf',
    showInOverview: true,
    overviewPageImage: '/domainPictures/overview/spf.jpg',
    overviewPageText: 'forschung_paraplegie_ch.text',
    favicons: {
      android_chrome_192: '/img/favicons/spf/android-chrome-192x192.png',
      android_chrome_256: '/img/favicons/spf/android-chrome-256x256.png',
      apple_touch_icon: '/img/favicons/spf/apple-touch-icon.png',
      favicon_16: '/img/favicons/spf/favicon-16x16.png',
      favicon_32: '/img/favicons/spf/favicon-32x32.png',
    },
    newsletterUrls: {},
  },
  sportmedizin_paraplegie_ch: {
    id: 'sportmedizin_paraplegie_ch',
    name: 'sportmedizin_paraplegie_ch.name',
    title: 'sportmedizin_paraplegie_ch.title',
    protocol: 'https://',
    host: 'www.paraplegie.ch',
    port: '',
    formattedHost: 'https://paraplegie.ch',
    pathPrefix: '/sportmedizin',
    fullUrl: 'https://paraplegie.ch/sportmedizin',
    homepagePath: '/home-sportmedizin',
    logo1x: {
      en: '/img/logos/svg/Sportmedizin-Nottwil-en.svg',
      de: '/img/logos/svg/Sportmedizin-Nottwil-de.svg',
      fr: '/img/logos/svg/Sportmedizin-Nottwil-fr.svg',
      it: '/img/logos/svg/Sportmedizin-Nottwil-it.svg',
    },
    headerLogo1x: '/img/logos/svg/smn-compact.svg',
    desktopLogo1x: '/img/logos/svg/Sportmedizin-Nottwil-de.svg',
    groupSVGWhite: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-white.svg',
    groupSVG: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
    groupSVGMobile: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
    logosvg: {
      en: '/img/logos/svg/Sportmedizin-Nottwil-en.svg',
      de: '/img/logos/svg/Sportmedizin-Nottwil-de.svg',
      fr: '/img/logos/svg/Sportmedizin-Nottwil-fr.svg',
      it: '/img/logos/svg/Sportmedizin-Nottwil-it.svg',
    },
    logoNoPaddingSvg: {
      en: '/img/logos/svg/no-padding/Sportmedizin-Nottwil-en.svg',
      de: '/img/logos/svg/no-padding/Sportmedizin-Nottwil-de.svg',
      fr: '/img/logos/svg/no-padding/Sportmedizin-Nottwil-fr.svg',
      it: '/img/logos/svg/no-padding/Sportmedizin-Nottwil-it.svg',
    },
    mail: 'sportmedizin@sportmedizin-nottwil.ch',
    tel: '+41419396600',
    formattedTel: '+41 41 939 66 00',
    sidebarMenu: 'sidebar-sportmedizin',
    quickLinksMenu: 'footer-quicklinks-sportmedi',
    sidebarMaxDepth: 2,
    mainMenu: 'main',
    metaNavigationMenu: 'meta-navigation',
    bodyClass: 'theme-smn',
    showInOverview: true,
    overviewPageImage: '/domainPictures/overview/sportmedizin.jpg',
    overviewPageText: 'sportmedizin_paraplegie_ch.text',
    favicons: {
      android_chrome_192:
        '/img/favicons/sportmedizin/android-chrome-192x192.png',
      android_chrome_256:
        '/img/favicons/sportmedizin/android-chrome-256x256.png',
      apple_touch_icon: '/img/favicons/sportmedizin/apple-touch-icon.png',
      favicon_16: '/img/favicons/sportmedizin/favicon-16x16.png',
      favicon_32: '/img/favicons/sportmedizin/favicon-32x32.png',
    },
    newsletterUrls: {},
  },
  fst_paraplegie_ch: {
    id: 'fst_paraplegie_ch',
    name: 'fst_paraplegie_ch.name',
    title: 'fst_paraplegie_ch.title',
    protocol: 'https://',
    host: 'www.paraplegie.ch',
    port: '',
    formattedHost: 'https://paraplegie.ch',
    homepagePath: '/home-fst',
    pathPrefix: '/fst',
    fullUrl: 'https://paraplegie.ch/fst',
    hideInFlyout: true,
    isExternal: true,
    showInOverview: true,
    overviewPageImage: '/domainPictures/overview/fst.jpg',
    overviewPageText: 'fst_paraplegie_ch.text',
  },
  activecommunication_paraplegie_ch: {
    id: 'activecommunication_paraplegie_ch',
    name: 'ac_paraplegie_ch.name',
    title: 'ac_paraplegie_ch.title',
    protocol: 'https://',
    host: 'www.paraplegie.ch',
    port: '',
    formattedHost: 'https://paraplegie.ch',
    pathPrefix: '/activecommunication',
    fullUrl: 'https://paraplegie.ch/activecommunication',
    homepagePath: '/home-activecommunication',
    logo1x: {
      en: '/img/logos/svg/AC_rgb.svg',
      de: '/img/logos/svg/AC_rgb.svg',
      fr: '/img/logos/svg/AC_rgb.svg',
      it: '/img/logos/svg/AC_rgb.svg',
    },
    headerLogo1x: '/img/logos/svg/AC_rgb.svg',
    desktopLogo1x: '/img/logos/svg/AC_rgb.svg',
    groupSVGWhite: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-white.svg',
    groupSVG: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
    groupSVGMobile: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
    logosvg: {
      en: '/img/logos/svg/AC_rgb.svg',
      de: '/img/logos/svg/AC_rgb.svg',
      fr: '/img/logos/svg/AC_rgb.svg',
      it: '/img/logos/svg/AC_rgb.svg',
    },
    logoNoPaddingSvg: {
      en: '/img/logos/svg/no-padding/AC_rgb.svg',
      de: '/img/logos/svg/no-padding/AC_rgb.svg',
      fr: '/img/logos/svg/no-padding/AC_rgb.svg',
      it: '/img/logos/svg/no-padding/AC_rgb.svg',
    },
    mail: 'office@activecommunication.ch',
    tel: '+41417470303',
    formattedTel: '+41 41 747 03 03',
    sidebarMenu: 'sidebar-ac',
    quickLinksMenu: 'footer-quicklinks-activecom',
    organisationFooterSocialMenu: 'footer-social-menu-activecom',
    sidebarMaxDepth: 2,
    bodyClass: 'theme-activecommunication',
    mainMenu: 'main',
    metaNavigationMenu: 'meta-navigation',
    showInOverview: true,
    overviewPageImage: '/domainPictures/overview/ac.jpg',
    overviewPageText: 'ac_paraplegie_ch.text',
    availableLanguages: ['de', 'fr', 'en'],
    favicons: {
      android_chrome_192:
        '/img/favicons/activecommunication/android-chrome-192x192.png',
      android_chrome_384:
        '/img/favicons/activecommunication/android-chrome-384x384.png',
      apple_touch_icon:
        '/img/favicons/activecommunication/apple-touch-icon.png',
      favicon_16: '/img/favicons/activecommunication/favicon-16x16.png',
      favicon_32: '/img/favicons/activecommunication/favicon-32x32.png',
    },
    newsletterUrls: {
      de: 'https://news.activecommunication.ch/pl/d1c/39cb/subscribe/de',
      fr: 'https://news.activecommunication.ch/pl/d1c/39cb/subscribe/fr',
    },
  },
  sirmed_paraplegie_ch: {
    id: 'sirmed_paraplegie_ch',
    name: 'sirmed_paraplegie_ch.name',
    title: 'sirmed_paraplegie_ch.title',
    protocol: 'https://',
    host: 'www.paraplegie.ch',
    port: '',
    formattedHost: 'https://paraplegie.ch',
    pathPrefix: '/sirmed',
    fullUrl: 'https://paraplegie.ch/sirmed',
    homepagePath: '/home-sirmed',
    logo1x: {
      en: '/img/logos/svg/Sirmed-en.svg',
      de: '/img/logos/svg/Sirmed-de.svg',
      fr: '/img/logos/svg/Sirmed-fr.svg',
      it: '/img/logos/svg/Sirmed-it.svg',
    },
    headerLogo1x: '/img/logos/svg/sirmed-compact.svg',
    desktopLogo1x: '/img/logos/svg/Sirmed-de.svg',
    groupSVGWhite: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-white.svg',
    groupSVG: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
    groupSVGMobile: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
    logosvg: {
      en: '/img/logos/svg/Sirmed-en.svg',
      de: '/img/logos/svg/Sirmed-de.svg',
      fr: '/img/logos/svg/Sirmed-fr.svg',
      it: '/img/logos/svg/Sirmed-it.svg',
    },
    logoNoPaddingSvg: {
      en: '/img/logos/svg/no-padding/Sirmed-en.svg',
      de: '/img/logos/svg/no-padding/Sirmed-de.svg',
      fr: '/img/logos/svg/no-padding/Sirmed-fr.svg',
      it: '/img/logos/svg/no-padding/Sirmed-it.svg',
    },
    mail: 'info@sirmed.ch',
    tel: '+41419395050',
    formattedTel: '+41 41 939 50 50',
    sidebarMenu: 'sidebar-sirmed',
    quickLinksMenu: 'footer-quicklinks-sirmed',
    organisationFooterSocialMenu: 'footer-social-menu-sirmed',
    sidebarMaxDepth: 2,
    mainMenu: 'main',
    metaNavigationMenu: 'meta-navigation',
    bodyClass: 'theme-sirmed',
    showInOverview: true,
    overviewPageImage: '/domainPictures/overview/sirmed.jpg',
    overviewPageText: 'sirmed_paraplegie_ch.text',
    favicons: {
      android_chrome_192: '/img/favicons/sirmed/android-chrome-192x192.png',
      android_chrome_256: '/img/favicons/sirmed/android-chrome-256x256.png',
      apple_touch_icon: '/img/favicons/sirmed/apple-touch-icon.png',
      favicon_16: '/img/favicons/sirmed/favicon-16x16.png',
      favicon_32: '/img/favicons/sirmed/favicon-32x32.png',
    },
    newsletterUrls: {
      de: 'https://news.sirmed.ch/pl/d1c/c976/subscribe/de',
    },
  },
  parahelp_paraplegie_ch: {
    id: 'parahelp_paraplegie_ch',
    name: 'parahelp_paraplegie_ch.name',
    title: 'parahelp_paraplegie_ch.title',
    protocol: 'https://',
    host: 'www.paraplegie.ch',
    port: '',
    formattedHost: 'https://paraplegie.ch',
    pathPrefix: '/parahelp',
    fullUrl: 'https://paraplegie.ch/parahelp',
    homepagePath: '/home-parahelp',
    logo1x: {
      en: '/img/logos/svg/parahelp.svg',
      de: '/img/logos/svg/parahelp.svg',
      fr: '/img/logos/svg/parahelp.svg',
      it: '/img/logos/svg/parahelp.svg',
    },
    headerLogo1x: '/img/logos/svg/parahelp-compact.svg',
    desktopLogo1x: '/img/logos/svg/parahelp.svg',
    groupSVGWhite: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-white.svg',
    groupSVG: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
    groupSVGMobile: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
    logosvg: {
      en: '/img/logos/svg/parahelp.svg',
      de: '/img/logos/svg/parahelp.svg',
      fr: '/img/logos/svg/parahelp.svg',
      it: '/img/logos/svg/parahelp.svg',
    },
    logoNoPaddingSvg: {
      en: '/img/logos/svg/no-padding/parahelp.svg',
      de: '/img/logos/svg/no-padding/parahelp.svg',
      fr: '/img/logos/svg/no-padding/parahelp.svg',
      it: '/img/logos/svg/no-padding/parahelp.svg',
    },
    mail: 'info@parahelp.ch',
    tel: '+41419396060',
    formattedTel: '+41 41 939 60 60',
    sidebarMenu: 'sidebar-parahelp',
    quickLinksMenu: 'footer-quicklinks-parahelp',
    organisationFooterSocialMenu: 'footer-social-menu-parahelp',
    sidebarMaxDepth: 2,
    mainMenu: 'main',
    metaNavigationMenu: 'meta-navigation',
    bodyClass: 'theme-parahelp',
    showInOverview: true,
    overviewPageImage: '/domainPictures/overview/parahelp.jpg',
    overviewPageText: 'parahelp_paraplegie_ch.text',
    favicons: {
      android_chrome_192: '/img/favicons/parahelp/android-chrome-192x192.png',
      android_chrome_256: '/img/favicons/parahelp/android-chrome-256x256.png',
      apple_touch_icon: '/img/favicons/parahelp/apple-touch-icon.png',
      favicon_16: '/img/favicons/parahelp/favicon-16x16.png',
      favicon_32: '/img/favicons/parahelp/favicon-32x32.png',
    },
    newsletterUrls: {
      de: 'https://news.parahelp.ch/pl/d1c/bc59/subscribe/de',
      fr: 'https://news.parahelp.ch/pl/d1c/bc59/subscribe/fr',
      it: 'https://news.parahelp.ch/pl/d1c/bc59/subscribe/it',
    },
  },
  orthotec_paraplegie_ch: {
    id: 'orthotec_paraplegie_ch',
    name: 'orthotec_paraplegie_ch.name',
    title: 'orthotec_paraplegie_ch.title',
    protocol: 'https://',
    host: 'www.paraplegie.ch',
    port: '',
    formattedHost: 'https://paraplegie.ch',
    pathPrefix: '/orthotec',
    fullUrl: 'https://paraplegie.ch/orthotec',
    homepagePath: '/home-orthotec',
    logo1x: {
      en: '/img/logos/svg/orthotec.svg',
      de: '/img/logos/svg/orthotec.svg',
      fr: '/img/logos/svg/orthotec.svg',
      it: '/img/logos/svg/orthotec.svg',
    },
    headerLogo1x: '/img/logos/svg/orthotec-compact.svg',
    desktopLogo1x: '/img/logos/svg/orthotec.svg',
    groupSVGWhite: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-white.svg',
    groupSVG: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
    groupSVGMobile: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
    logosvg: {
      en: '/img/logos/svg/orthotec.svg',
      de: '/img/logos/svg/orthotec.svg',
      fr: '/img/logos/svg/orthotec.svg',
      it: '/img/logos/svg/orthotec.svg',
    },
    logoNoPaddingSvg: {
      en: '/img/logos/svg/no-padding/orthotec.svg',
      de: '/img/logos/svg/no-padding/orthotec.svg',
      fr: '/img/logos/svg/no-padding/orthotec.svg',
      it: '/img/logos/svg/no-padding/orthotec.svg',
    },
    mail: 'info@orthotec.ch',
    tel: '+41419395606',
    formattedTel: '+41 41 939 56 06',
    sidebarMenu: 'sidebar-orthotec',
    quickLinksMenu: 'footer-quicklinks-orthotec',
    organisationFooterSocialMenu: 'footer-social-menu-orthotec',
    sidebarMaxDepth: 2,
    mainMenu: 'main',
    metaNavigationMenu: 'meta-navigation',
    bodyClass: 'theme-orthotec',
    showInOverview: true,
    overviewPageImage: '/domainPictures/overview/orthotec.jpg',
    overviewPageText: 'orthotec_paraplegie_ch.text',
    favicons: {
      android_chrome_192: '/img/favicons/orthotec/android-chrome-192x192.png',
      android_chrome_256: '/img/favicons/orthotec/android-chrome-256x256.png',
      apple_touch_icon: '/img/favicons/orthotec/apple-touch-icon.png',
      favicon_16: '/img/favicons/orthotec/favicon-16x16.png',
      favicon_32: '/img/favicons/orthotec/favicon-32x32.png',
    },
    newsletterUrls: {
      de: 'https://news.orthotec.ch/pl/d1c/8ca6/subscribe/de',
      fr: 'https://news.orthotec.ch/pl/d1c/8ca6/subscribe/fr',
      it: 'https://news.orthotec.ch/pl/d1c/8ca6/subscribe/it',
    },
  },
  orthotecsports_paraplegie_ch: {
    id: 'orthotecsports_paraplegie_ch',
    name: 'orthotecsports_paraplegie_ch.name',
    title: 'orthotecsports_paraplegie_ch.title',
    protocol: 'https://',
    host: 'www.paraplegie.ch',
    port: '',
    formattedHost: 'https://paraplegie.ch',
    pathPrefix: '/orthotecsports',
    fullUrl: 'https://paraplegie.ch/orthotecsports',
    homepagePath: '/home-orthotecsports',
    logo1x: {
      en: '/img/logos/svg/orthotec.svg',
      de: '/img/logos/svg/orthotec.svg',
      fr: '/img/logos/svg/orthotec.svg',
      it: '/img/logos/svg/orthotec.svg',
    },
    headerLogo1x: '/img/logos/svg/orthotec-compact.svg',
    desktopLogo1x: '/img/logos/svg/orthotec.svg',
    groupSVG: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe.svg',
    groupSVGMobile: '/img/logos/svg/Schweizer-Paraplegiker-Gruppe-home.svg',
    logosvg: {
      en: '/img/logos/svg/orthotec.svg',
      de: '/img/logos/svg/orthotec.svg',
      fr: '/img/logos/svg/orthotec.svg',
      it: '/img/logos/svg/orthotec.svg',
    },
    logoNoPaddingSvg: {
      en: '/img/logos/svg/no-padding/orthotec.svg',
      de: '/img/logos/svg/no-padding/orthotec.svg',
      fr: '/img/logos/svg/no-padding/orthotec.svg',
      it: '/img/logos/svg/no-padding/orthotec.svg',
    },
    mail: 'info@orthotec.ch',
    tel: '+41419395606',
    formattedTel: '+41 41 939 56 06',
    sidebarMenu: 'sidebar-orthotecsports',
    quickLinksMenu: 'footer-quicklinks-orthotecsports',
    sidebarMaxDepth: 2,
    mainMenu: 'main',
    metaNavigationMenu: 'meta-navigation-orthotecsports',
    bodyClass: 'theme-orthotec',
    showInOverview: true,
    overviewPageImage: '/domainPictures/overview/orthotec.jpg',
    overviewPageText: 'orthotec_paraplegie_ch.text',
    favicons: {
      android_chrome_192: '/img/favicons/orthotec/android-chrome-192x192.png',
      android_chrome_256: '/img/favicons/orthotec/android-chrome-256x256.png',
      apple_touch_icon: '/img/favicons/orthotec/apple-touch-icon.png',
      favicon_16: '/img/favicons/orthotec/favicon-16x16.png',
      favicon_32: '/img/favicons/orthotec/favicon-32x32.png',
    },
    newsletterUrls: {
      de: 'https://news.orthotec.ch/pl/d1c/8ca6/subscribe/de',
      fr: 'https://news.orthotec.ch/pl/d1c/8ca6/subscribe/fr',
      it: 'https://news.orthotec.ch/pl/d1c/8ca6/subscribe/it',
    },
  },
};

export const homepagePaths = Object.values(DOMAINS).map(
  (domain) => domain.homepagePath,
);
